import { i18nRoute } from '@/../routes.js';
import { project } from '@/lib/project';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
// import {
// 	BR,
// 	CA,
// 	DE,
// 	ES,
// 	FR,
// 	GB,
// 	IT,
// 	MX,
// 	PL,
// 	PT,
// 	US,
// 	ZA,
// } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

const CountryFlag = dynamic(() => import('./CountryFlag', { ssr: false }));

function getTranslatedPath({ pathname, query, locale }) {
	return i18nRoute(pathname.replace('...', ''), locale, query);
}

// function getProjectLanguageFlag(locale = project.defaultLocale) {
// 	const [lang, loc] = locale.split('-');
// 	if (loc) {
// 		return getFlag(loc);
// 	}

// 	return getFlag(project.regions[locale]);
// }

// function getFlag(loc) {
// 	let Flag = GB;
// 	switch (loc) {
// 		case 'US':
// 		case 'EN':
// 			Flag = US;
// 			break;
// 		case 'CA':
// 			Flag = CA;
// 			break;
// 		case 'GB':
// 			Flag = GB;
// 			break;
// 		case 'DE':
// 			Flag = DE;
// 			break;
// 		case 'PT':
// 			Flag = PT;
// 			break;
// 		case 'BR':
// 			Flag = BR;
// 			break;
// 		case 'PL':
// 			Flag = PL;
// 			break;
// 		case 'ES':
// 			Flag = ES;
// 			break;
// 		case 'MX':
// 			Flag = MX;
// 			break;
// 		case 'FR':
// 			Flag = FR;
// 			break;
// 		case 'IT':
// 			Flag = IT;
// 			break;
// 		case 'ZA':
// 			Flag = ZA;
// 			break;
// 		default:
// 			Flag = GB;
// 	}
// 	return <Flag className="h-4 w-5" />;
// }

export function LanguageSwitcher({ className, onSelect, languageAlternates }) {
	const router = useRouter();
	const { pathname, query } = router;
	const { t } = useTranslation('common');

	const countryCode = project.regions[router.locale];

	const availableLocales = languageAlternates
		? router.locales.filter((locale) =>
				languageAlternates.find((l) => l.hrefLang === locale)
		  )
		: router.locales;

	const getLanguageAlternativeHref = ({ locale }) => {
		const languageAlternate = languageAlternates
			? languageAlternates.find((l) => l.hrefLang === locale)
			: null;

		if (languageAlternate) {
			const url = new URL(languageAlternate.href);
			return url.pathname;
		}

		return getTranslatedPath({
			pathname,
			query,
			locale,
		});
	};

	return (
		<Menu as="div" className={clsx('relative text-left', className)}>
			<div>
				<Menu.Button
					aria-label={`language switcher:${router.locale}`}
					className="group inline-flex cursor-pointer items-center justify-center rounded-lg px-2 py-1 text-sm text-slate-700 ring-1 ring-gray-300 hover:bg-blue-50 hover:text-gray-900"
				>
					<CountryFlag countryCode={countryCode} className={'size-5'} />
					<ChevronDownIcon
						className="-mr-1 ml-1 h-5 w-5 shrink-0 text-gray-400 group-hover:text-gray-500"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			{availableLocales.length > 1 && (
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-40 mt-2 w-40 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-hidden">
						<div className="py-1">
							{availableLocales
								.filter((l) => l !== 'default')
								.map((loc) => (
									<Menu.Item key={loc} disabled={router.locale === loc}>
										{({ active }) => (
											<Link
												onClick={() => onSelect && onSelect()}
												href={{ pathname, query }}
												as={getLanguageAlternativeHref({ locale: loc })}
												locale={loc}
												className={clsx(
													router.locale === loc
														? 'font-medium text-gray-900'
														: 'text-gray-600',
													active ? 'bg-blue-100' : '',
													'flex items-center px-4 py-2 text-sm capitalize'
												)}
											>
												<CountryFlag
													countryCode={project.regions[loc]}
													className={'size-5'}
												/>
												<span className="ml-2">
													{t(`common:language.${loc}`)}
												</span>
											</Link>
										)}
									</Menu.Item>
								))}
						</div>
					</Menu.Items>
				</Transition>
			)}
		</Menu>
	);
}
