import logoBusBuster from '@/images/projects/busbuster.svg';
import { project } from '@/lib/project';
import Image from 'next/image';
import Link from 'next/link';

export function Logo(props) {
	const { linkClassName, className, width, height, priority, href, callback } =
		props;
	if (href) {
		return (
			<Link
				onClick={() => callback && callback()}
				prefetch={false}
				href={href}
				className={linkClassName || ''}
				title={`${project.name} logo`}
			>
				<span className="sr-only">{project.name}</span>
				<Image
					alt={`${project.name} logo`}
					title={`${project.name} logo`}
					priority={priority}
					src={logoBusBuster}
					width={width}
					height={height}
					className={className}
				/>
			</Link>
		);
	}

	return (
		<Image
			alt={`${project.name} logo`}
			title={`${project.name} logo`}
			priority={priority}
			src={logoBusBuster}
			width={width}
			height={height}
			className={className}
		/>
	);
}
