import Link from 'next/link';

import { Logo } from '@/components/ui/Logo';
import useMenuNavigation from '@/hooks/useNavigation';
import { project } from '@/lib/project';
import { useTranslation } from 'next-i18next';

export function FooterBase({ navigation }) {
	return (
		<div className="mt-8 flex flex-col-reverse items-center border-t border-slate-400/10 py-4 sm:flex-row sm:justify-between">
			<FooterCopyright />
			<div className="flex gap-x-4">
				{navigation.social.map((item) => (
					<a
						target="_blank"
						rel="noreferrer noopener"
						key={item.name}
						href={item.href}
						className="text-gray-400 hover:text-gray-500"
					>
						<span className="sr-only">{item.name}</span>
						<item.icon className="h-6 w-6" aria-hidden="true" />
					</a>
				))}
			</div>
		</div>
	);
}

export function FooterCopyright() {
	const { t } = useTranslation(['common']);
	return (
		<p className="mt-4 text-center text-sm text-slate-500 sm:mt-0 sm:text-left">
			{t('common:footer.copyright', {
				date: new Date().getFullYear(),
				project: project.name,
			})}
		</p>
	);
}

export default function Footer() {
	const navigation = useMenuNavigation();
	const mainLinks = [
		...navigation.company.children,
		navigation.helpCenter,
		...navigation.legal.children,
	];
	return (
		<footer className="bg-white">
			<div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
				<Logo className="mx-auto h-10 w-auto" footer />
				<nav className="mt-8 text-sm" aria-label="quick links">
					<div className="flex flex-col justify-center gap-y-4 lg:flex-row lg:gap-x-4">
						{mainLinks.map((item) => (
							<Link
								prefetch={false}
								key={item.name}
								href={item.href}
								title={item.name}
								className="inline-block text-sm font-medium text-gray-500 hover:text-gray-900"
							>
								{item.name}
							</Link>
						))}
					</div>
				</nav>
				<FooterBase navigation={navigation} />
			</div>
		</footer>
	);
}
