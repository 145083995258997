import SEO from '@/../next-seo.config';
import Footer from '@/components/ui/Footer.jsx';
import Header from '@/components/ui/Header.jsx';
import { useGAPageViews } from '@/hooks/useGAPageViews';
import { project } from '@/lib/project';
import '@/styles/globals.css';
import { GoogleAnalytics } from '@next/third-parties/google';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from 'next-i18next.config';
import { DefaultSeo, NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script.js';
import { getCanonicalUrl } from 'routes.js';

const GoogleAdVignette = dynamic(
	() => import('@/components/@google/GoogleAdVignette'),
	{
		ssr: false,
	}
);

const GoogleAdAnchor = dynamic(
	() => import('@/components/@google/GoogleAdAnchor'),
	{
		ssr: false,
	}
);

//If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ['latin'] });

const MyApp = ({ Component, pageProps }) => {
	const router = useRouter();
	const languageAlternates = [];

	if (pageProps.languageAlternates) {
		languageAlternates.push(...pageProps.languageAlternates);
	} else {
		languageAlternates.push(
			...router.locales
				.map((locale) => {
					if (locale === 'default') {
						return null;
					}
					return {
						hrefLang: locale,
						href: getCanonicalUrl(router.pathname, locale, router.query),
					};
				})
				.filter(Boolean)
		);
	}
	const defaultVersion = languageAlternates.find(
		(l) => l.hrefLang === project.defaultLocale
	);
	if (defaultVersion) {
		languageAlternates.push({
			hrefLang: 'x-default',
			href: defaultVersion.href,
		});
	}

	useGAPageViews({
		gaMeasurementId: project.googleAnalyticsId,
	});

	return (
		<>
			<DefaultSeo
				{...SEO}
				dangerouslySetAllPagesToNoIndex={project.noIndex}
				dangerouslySetAllPagesToNoFollow={project.noIndex}
			/>
			<NextSeo languageAlternates={languageAlternates} />
			{pageProps.schema && (
				<Script
					strategy="beforeInteractive"
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(pageProps.schema),
					}}
				/>
			)}
			{pageProps.schemas &&
				pageProps.schemas.map((schema) => (
					<Script
						key={`${schema['@type']}_${schema['name']}`}
						strategy="beforeInteractive"
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(schema),
						}}
					/>
				))}

			<div className={`${inter.className} antialiased`}>
				{!pageProps.disableHeader && (
					<Header
						{...pageProps.headerProps}
						languageAlternates={languageAlternates}
					/>
				)}
				<Component {...pageProps} />
				{!pageProps.disableFooter && <Footer />}
			</div>
			<GoogleAnalytics gaId={project.googleAnalyticsId} />
			<Script id="google-analytics" strategy="afterInteractive">
				{`window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('set', 'url_passthrough', true);
					gtag('set', 'ads_data_redaction', true);
				`}
			</Script>
			<Script id="bing-ads-tag" strategy="afterInteractive">
				{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${project.bing.conversionId}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
			</Script>
			<SpeedInsights />
			<Analytics />
			<GoogleAdVignette />
			<GoogleAdAnchor />
			<Script
				id="booking-affiliate"
				strategy="afterInteractive"
				src="//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js"
			/>
		</>
	);
};

export default appWithTranslation(MyApp, nextI18nextConfig);
